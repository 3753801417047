<template>
  <p class="tw-text-2xl tw-font-medium tw-m-0 dark--text"><slot /></p>
</template>

<script>
export default {
  name: "AtomTitle",
  props: ["text"]
};
</script>
