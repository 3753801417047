<template>
  <v-app>
    <v-container class="tw-w-full sm:tw-w-1/2 md:tw-max-w-md tw-mx-auto tw-h-full tw-flex tw-justify-center tw-items-center">
      <v-row>
        <v-col cols="12">
          <!-- head -->
          <Header title="Reset Password" />
          <!-- end head -->
        </v-col>
        <v-col cols="12">
          <!-- Form Here -->
          <ForgotForm
            v-if="!isNewPassword"
            :form="form"
            :doSubmit="doResendCode"
            :doUpdateData="doUpdateData"
            :isProcessing="isProcessing"
            :timeleft="timeleft"
            :isResend="isResend"
          />
          <NewPasswordForm 
            v-else
            :form="form"
            :doSubmit="doSubmitNewPassword"
            :doUpdateData="doUpdateData"
            :doResendCode="doResendCode"
            :isProcessing="isProcessing"
            :timeleft="timeleft"
            :isResend="isResend"
          />
          
        </v-col>
      </v-row>
    </v-container>
    <Credits />
    <base-snack-bars :objects.sync="snackBars" />
  </v-app>
</template>

<script>
// atoms
import Title from '@/components/atoms/Title'
import TextNormal from '@/components/atoms/TextNormal'

//utilities
import {
  cloneDeep,
} from 'lodash'
import { initComponent } from '@/lib'


import {
  mapState,
  mapActions,
  mapMutations
} from 'vuex'

import {
  REQUEST_NEW_PASSWORD,
  REQUEST_RESET_PASSWORD,
  // REQUEST_MFA,
  // RESET_LOGIN_STATE,
  UPDATE_LOGIN_STATE
} from '@/store/modules/login'

export default initComponent('forgot', {
  isPage: true,
  components: {
    Title,
    TextNormal,
    ForgotForm: () => import('./components/ForgotForm'),
    NewPasswordForm: () => import('./components/NewPasswordForm'),
    Credits: () => import('../components/Credits'),
    Header: () => import('../components/Header'),
  },
  data: () => ({
    //not state management data
  }),
  created() {
  },
  computed: {
    ...mapState({
      isProcessing: (state) => state.login.isProcessing,
      form: (state) => state.login.form,
      isResend: (state) => state.login.isResend,
      timeleft: (state) => state.login.timeleft,
      isNewPassword: (state) => state.login.isNewPassword,
      
    }),
  },
  methods: {
    doUpdateData (value, key) {
      const { commit, state } = this.$store
      const form = cloneDeep(state.login.form)
      form[key] = value
      return commit(UPDATE_LOGIN_STATE, { form })
    },
    countdown (time) {
      const { commit } = this.$store;
      commit(UPDATE_LOGIN_STATE, { timeleft: time })
      let downloadTimer = setInterval(function(){
        if(time === 0){
          clearInterval(downloadTimer);
          return commit(UPDATE_LOGIN_STATE, { isResend: false })
        } else {
          commit(UPDATE_LOGIN_STATE, { timeleft: time })
        }
        --time;
      }, 1000);
      return downloadTimer
    },
    ...mapActions({
      async doSubmitNewPassword (dispatch) {
        await dispatch(REQUEST_NEW_PASSWORD)
      },
      async doResendCode (dispatch) {
        await dispatch(REQUEST_RESET_PASSWORD)
        await this.countdown(30);
      },
    }),
    ...mapMutations({}),
    // submit () {
    //   this.$refs.observer.validate()
    //   this.$router.push({ name: ROUTE_DASHBOARD })
    // },
    // clear () {
    //   this.form = {}
    //   this.$refs.observer.reset()
    // },
  },
})
</script>

<style lang="sass" scope>
  .theme--light.v-application
    background: #fff !important

  .v-label
    font-size: 14px !important
</style>